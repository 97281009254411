import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UploadFileDataModel } from '../_models/upload-file-data-model';
import { EmployeeRecordService } from '../_services/employee-record.service';
import { SupportRequestService } from '../_services/support-request.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hiring-position',
  templateUrl: './hiring-position.component.html',
  styleUrls: ['./hiring-position.component.scss']
})
export class HiringPositionComponent implements OnInit {
  upLoadFileList: Array<UploadFileDataModel> = [];
  fieldRequiredFlag: boolean = false;
  supportReqForm: FormGroup;
  refrenceForm: FormGroup;
  datasplit: any;
  hiringDataList: any[];
  employeId: string;
  goalFormSubmitted: boolean = false;

  constructor(
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private employeeRecordService: EmployeeRecordService,
    private supportReqService: SupportRequestService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    let empId = localStorage.getItem('empId');
    this.employeId = empId

    this.supportReqForm = this.fb.group({
      Position: [''],
      Skills: [''],
      Location: [''],
      HomeAvailable: [''],
      Experience: [''],
      ClosureDate: [''],
      description: [''],
      closureDate: [''],

    });
    this.refrenceForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]], // Assuming mobileNumber should be a 10-digit number.
      email: ['', [Validators.required, Validators.email]],
      refrenceProvidedBy: [''],
      Position: [''],
      Position1: ['']
    });
    // this.formvaluePatch()
    this.getHiringPositionData()
  }

  //API Of Show Grid List Of Hiring Open Position
  getHiringPositionData() {
    this.employeeRecordService.getHiringPosition().subscribe((GridResponce) => {
      if (GridResponce.isSuccessful == true) {
        this.hiringDataList = GridResponce.data
        this.spinner.hide();
      }
      else if (GridResponce.isSuccessful == false) {
        this.spinner.hide();
        if (GridResponce?.messageDetail?.message_code != 204) {

          this.toastr.error(GridResponce?.messageDetail?.message, 'Error');
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    })

  }

  //Open Hiring Dewtails Model PoUp
  openHiringDetails(position: string, item) {
    debugger
    this.spinner.show();
    this.router.navigate(['/ESSPortal/open-position/' + item]);
    this.hiringDataList.forEach(element => {
      if (element.hiringId == item) {
        this.supportReqForm.patchValue({
          Position: element?.hiringPosition,
          Skills: element?.skillsRequired,
          Location: element?.location,
          HomeAvailable: element?.isWorkFromHomeAvailable,
          Experience: element?.experienceRange,
          description: element?.jobDescription,
          closureDate: element?.closureDate
        });
        this.spinner.hide();

      }
      // format of expected resolution date so as to display in form for Employee.
      if (this.supportReqForm.value.closureDate != "0001-01-01T00:00:00") {
        this.supportReqForm.value.closureDate = moment(this.supportReqForm.value.closureDate).format('DD-MM-YYYY');
        this.supportReqForm.patchValue({
          closureDate: this.supportReqForm?.value?.closureDate
        });
      }
      this.spinner.hide();

    });
  }
  openForm(position: string, item) {
    debugger

    this.hiringDataList.forEach(element => {
      if (element.hiringId == item) {
        this.refrenceForm.patchValue({
          Position: element.hiringPosition,
          Position1: element.hiringId,
        });

      }
      this.refrenceForm.updateValueAndValidity();
    });
  }

  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        // Check if the file with the same name already exists in the array
        const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
        if (fileExists) {
          this.toastr.error("File with the same name already exists");
          setTimeout(() => {
            this.toastr.clear();
          }, 3000);
          continue; // Skip this file and move on to the next one
        }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_candidateapplication";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  async uploadFiles(attachFiles: any) {
    await this.supportReqService.postUploadFilesWithToDo(attachFiles).subscribe((upRes: any) => {
      if (upRes.ResponseCode == 100) { // success
 
           this.toastr.success( 'Record Submit Successfully','Success', {//Success
          positionClass: 'toast-top-full-width',

        });
        this.spinner.hide();
      }

      else if (upRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(upRes.Message);
      }

     
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }
  //empGoalsForm Form Control
  get egl() {
    return this.refrenceForm.controls;
  }


  onSubmit() {
    this.spinner.show();
    this.goalFormSubmitted = true;
    if (this.refrenceForm.invalid) {
      // Mark all form controls as touched to trigger validation messages
      Object.values(this.refrenceForm.controls).forEach(control => {
        control.markAsTouched();
        this.fieldRequiredFlag = true;
      });
      this.spinner.hide();
      if (this.upLoadFileList.length == 0) {
        return
      }
      return;

    }
    if (this.upLoadFileList.length == 0) {
      this.spinner.hide();
      return
    }




    let testObj = {
      firstName: this.refrenceForm.value.firstName,
      lastName: this.refrenceForm.value.lastName,
      mobileNumber: this.refrenceForm.value.mobileNumber,
      email: this.refrenceForm.value.email,
      // Position:this.refrenceForm.value.Position,
      refrenceProvidedBy: {
        id: this.employeId
      },
      Position: {
        id: this.refrenceForm.value.Position1
      }
    }

    // POST API Of Submit Application Form
    this.employeeRecordService.postapplyApplicationForm(testObj).subscribe((Responset: any) => {

      if (Responset.isSuccessful == true) {

        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = Responset.Data;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }
        // this.toastr.success(Responset.message, 'Record Submit Successfully', {//Success
        //   positionClass: 'toast-top-full-width',

        // });


        // setTimeout(() => {
        //   location.reload();
        //   this.router.navigate(['/ESSPortal/my-hiring-application']);
        //   this.spinner.hide();
        // }, 1000);
        
        // this.spinner.hide();

      }

      else if (Responset.isSuccessful == false) {
        if (Responset?.messageDetail?.message_code != 204) {

          this.toastr.error(Responset.Message, 'Error');
        }
        this.spinner.hide();
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    });
  }




}
