import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { TabViewModule } from 'primeng/tabview';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-performance-reviews-form',
  templateUrl: './performance-reviews-form.component.html',
  styleUrls: ['./performance-reviews-form.component.scss']
})

export class PerformanceReviewsFormComponent implements OnInit {
  allQuestionData: any
  fullName: string;
  subjectiveQue: any[] = [];
  objectiveQue: any[] = [];
  feedbackQue: any[] = [];

  empGoalsForm: FormGroup;
  PerformanceReviewForm: FormGroup

  RatingList: any[]
  workFormSubmitted: boolean;
  userData: EmployeeDataModel;
  responseId: any;
  todayDate = new Date();
  minDate: string;
  maxDate: string
  secondGoalMinDate: string
  secondGoalmaxDate: string
  thirdGoalMinDate: string
  thirdGoalMaxDate: string
  reviewResponseDataList: any[] = [];
  CheckreviewResponseDataList: any
  ReviewDataLst: { PerFormanceRevStatus: any; }[];
  PerformanceRevName: any;
  description: any;
  PerformanceRevId: any;
  responseData: any;

  // questionType: any;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private PerformanceReviewService: PerformanceReviewService,
    private storage: StorageMap,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute

  ) {
    this.PerformanceReviewForm = this.fb.group({
      // Define your form controls here
      Managers: ['', Validators.required],
      Projects: ['', Validators.required],
      rating: ['', Validators.required],
      name: ['', Validators.required],
      designation: ['', Validators.required],
      goal1: ['', Validators.required],
      goal2: ['', Validators.required],
      goal3: ['', Validators.required],
      expectedOutcomes1: ['', Validators.required],
      expectedOutcomes2: ['', Validators.required],
      expectedOutcomes3: ['', Validators.required],
      achievedDate1: ['', Validators.required],
      achievedDate2: ['', Validators.required],
      achievedDate3: ['', Validators.required],
      // Add other form controls as needed
    });

    // Calculate minDate and maxDate for according to Goals of the current year
    const currentYear = this.todayDate.getFullYear();
    this.minDate = moment(`${currentYear}-04-01`).format('YYYY-MM-DD');
    this.maxDate = moment(`${currentYear}-06-30`).format('YYYY-MM-DD');
    this.secondGoalMinDate = moment(`${currentYear}-07-01`).format('YYYY-MM-DD');
    this.secondGoalmaxDate = moment(`${currentYear}-09-30`).format('YYYY-MM-DD');
    this.thirdGoalMinDate = moment(`${currentYear}-10-01`).format('YYYY-MM-DD');
    const nextYear = currentYear + 1;
    // Append the range from January to March of the next year
    this.thirdGoalMaxDate = moment(`${nextYear}-03-31`).format('YYYY-MM-DD');


  }
  ngOnInit(): void {
    this.responseId = this.route.snapshot.paramMap.get('id');
    this.spinner.show();
    this.getDropDownRatingReviewStatus()
    this.getData();


  }

  async getData() {

    this.userData = new EmployeeDataModel();
    await this.storage.get('empDetails').subscribe((myInvoice: EmployeeDataModel) => {
      this.userData = myInvoice.userProfile;
      // If Contact User is logged in
      this.userData.ID = this.userData?.userId;
      this.userData.fullName = this.userData?.fullName;
      this.userData.designation = this.userData?.designation
      this.userData.reportingManager = this.userData?.reportingManager
      if (this.userData?.reportingManager?.id) {

        this.userData.reportingManagerID = this.userData?.reportingManager?.id
      }
      // this.GetReviewResponseforLoggedInEmployee();
    
      this.GetPerfReviewQuetions();

      this.PerformanceReviewForm.patchValue({
        name: this.userData?.fullName,
        designation: this.userData?.designation

      })
      // this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  //Employee Form Control
  get prf() {
    return this.PerformanceReviewForm.controls;
  }

  // ############################## Get all Dropdown of Rating ###################################
  getDropDownRatingReviewStatus() {
    // this.spinner.show();
    this.PerformanceReviewService.getDropDownRatingReviewStatus().subscribe((dropdownResponce: any) => {
      if (dropdownResponce.ResponseCode == 100) { // success
        this.RatingList = dropdownResponce.Data;
        // this.spinner.hide();
      }
      else if (dropdownResponce.ResponseCode == 999) {
        this.toastr.error(dropdownResponce.Message, 'Error');
        this.spinner.hide();
      }
      else if (dropdownResponce.ResponseCode == 217) {
        this.toastr.error(dropdownResponce.Message, 'Error');
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    })
  }

  // ################################## API Call Show ALl Type Of Questions In Performance Review Blank Form ######  
  GetPerfReviewQuetions() {debugger

    this.spinner.show();

    this.allQuestionData = []; // Ensure allQuestionData is initialized as an array
    this.PerformanceReviewService.GetPerfReviewQuetions(this.responseId).subscribe((questionresponce: any) => {
      if (questionresponce.ResponseCode == 100) {

        const { PerFormanceRevStatus } = questionresponce?.Data;
        //     // Format the data into an array
        this.ReviewDataLst = [{
          PerFormanceRevStatus,
        }];
        // this.spinner.hide();
        this.PerformanceRevName = questionresponce?.Data?.PerformanceRevName
        this.description = questionresponce?.Data?.Notes
        this.PerformanceRevId = questionresponce?.Data?.PerformanceRevId
        this.allQuestionData = questionresponce?.Data?.PerformanceRevResDetails;
        // Extract PerformanceRevResDetails array from questionresponce.Data
        const questionsArray = questionresponce.Data.PerformanceRevResDetails;

        // Ensure questionsArray is an array before proceeding
        if (Array.isArray(questionsArray)) {
          
          // Iterate over the questions array
          questionsArray.forEach((question: any) => {
            let defaultValue;
            const validators = question ? [Validators.required] : null;
            if (question.Category.Label == "Subjective") {
              this.subjectiveQue.push(question);
              this.subjectiveQue.sort((a, b) => a.Sequence - b.Sequence);
              defaultValue = question.Category.Label === 'Subjective' ? [] : [];
            } else if (question.Category.Label == "Objective") {
              this.objectiveQue.push(question);
              this.objectiveQue.sort((a, b) => a.Sequence - b.Sequence);
              defaultValue = question.Category.Label === 'Objective' ? [] : [];
            } else if (question.Category.Label == "Feedback") {
              this.feedbackQue.push(question);
              this.feedbackQue.sort((a, b) => a.Sequence - b.Sequence);
              defaultValue = question.Category.Label === 'Feedback' ? [] : [];
            }

            this.PerformanceReviewForm.addControl(
              question.ID,
              this.fb.control(defaultValue, validators)
            );
            if (question.RatingEnabled) {
              const defaultValue = question.Rating ? question.Rating : null; // You may adjust the default value as needed
              this.PerformanceReviewForm.addControl(
                question.ID + 'ratting',
                this.fb.control(defaultValue, validators)
              );
            }
            if (PerFormanceRevStatus.Value == 674180001) {
              this.PerformanceReviewForm.disable();
            }
          });
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }
      else if (questionresponce.ResponseCode == 999) {
        this.toastr.error(questionresponce.Message, 'Error');
        this.spinner.hide();
      }
      else if (questionresponce.ResponseCode == 217) {
        // this.toastr.error(questionresponce.Message, 'Error');
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // sending Record id to Update performance form page.
  performanceDetails(id: string) {

    this.router.navigate(['/ESSPortal/update-performance-review-form/' + id]);
  }
  //Performance Form Control
  get wlf() {
    this.spinner.hide();
    return this.PerformanceReviewForm.controls;
  }


  submit() { 
    this.spinner.show();

    this.workFormSubmitted = true;
    if (this.PerformanceReviewForm.valid) {
      let performanceRevResDetails1 = [];
      let goalsList1 = [];
      let keyArray = Object.keys(this.PerformanceReviewForm.value);



      // Extract goals data from the form
      for (let i = 1; i <= 3; i++) {

        let goalKey = 'goal' + i;
        let outcomeKey = 'expectedOutcomes' + i;
        let achievedDateKey = 'achievedDate' + i;
        let goal = this.PerformanceReviewForm.value[goalKey];
        let outcome = this.PerformanceReviewForm.value[outcomeKey];
        let achievedDate = this.PerformanceReviewForm.value[achievedDateKey];

        // Only add to goalsList if goal is not empty
        // if (goal?.trim() !== '') {
        goalsList1.push({
          GoalSequence: i, // Set the sequence number here
          Goal: goal,
          ExpOutcome: outcome,
          AchievebyDate: achievedDate,
          EmployeeId: this.userData.ID
        });
        // }
        // else {
        //   // this.spinner.hide();
        // }
      }

      keyArray.forEach((key: string) => {
        let exist = this.allQuestionData.find((data: any) => data.ID === key);
        if (exist) {
          let controlValue = this.PerformanceReviewForm.get(key)?.value;
          // Ensure controlValue is a 
          if (controlValue == null) {
            controlValue = ''; // Set it to an empty string
          } else {
            // Ensure controlValue is a string
            if (typeof controlValue !== 'string') {
              // If controlValue is not a string, convert it to a string
              controlValue = String(controlValue);
            }
          }
          // if (typeof controlValue !== 'string') {
          //   // If controlValue is not a string, convert it to a string
          //   controlValue = String(controlValue);
          // }
          let controlRatingValue = key + 'ratting'; // Corrected typo from 'ratting' to 'rating'

          let ratingValue = String(this.PerformanceReviewForm.value[controlRatingValue]);
          // Ensure ratingValue is a string
          if (typeof ratingValue !== 'string') {
            // If ratingValue is not a string, convert it to a string
            ratingValue = String(ratingValue);
          }
          if(exist.Category.Label !== "Feedback"){
          let detail = {
            Question: exist.Question,
            Answer: controlValue,
            RatingEnabled: exist.RatingEnabled,
            Sequence: exist.Sequence,
            Category: {
              Value: exist.Category.Value.toString() // Convert to string using toString() method
            },

            Rating: {
              Value: ratingValue
            },
            LinkedReviewFY: {
              ID: exist.LinkedReviewFY.ID
            }
          };

          performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
        }
          // No need to construct the final object inside the loop
          if(exist.Category.Label == "Feedback"){
            let detail = {
              Question: exist.Question,
              FeedbackResponse: controlValue,
              RatingEnabled: exist.RatingEnabled,
              Sequence: exist.Sequence,
              Category: {
                Value: exist.Category.Value.toString() // Convert to string using toString() method
              },
    
              Rating: {
                Value: ratingValue
              },
              LinkedReviewFY: {
                ID: exist.LinkedReviewFY.ID
              }
            };
            performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
          }
        }
        // else {
        //   // this.spinner.hide();
        // }
      });

      let object: any = {  // Initialize object outside the loop
        EmployeeId: this.userData.ID,
        Managers: this.PerformanceReviewForm.value.Managers,
        ReportingManId: this.userData?.reportingManager?.ID,
        LinkedReviewFY: this.PerformanceRevId,
        Projects: this.PerformanceReviewForm.value.Projects,
        OverallSelfRating: {
          Value: this.PerformanceReviewForm.value.rating
        },
        Status: {
          Value: "674180002"
        },
        PerformanceRevResDetails: performanceRevResDetails1, // Initialize PerformanceRevResDetails here
        goalsList: goalsList1 // Assuming there's no logic for goalsList here
      };
      // Send object to API after loop
      this.PerformanceReviewService.SavePerfReviewResponse(object).subscribe({
        next: (response: any) => {
          if (response.ResponseCode == 100) {
            this.responseData = response.Data
            this.toastr.success('Your Performance Review record is Submitted successfully and will now proceed for Manager and HR Review. ', 'Success', {
              positionClass: 'toast-top-full-width',
              timeOut: 15000 // Set to 15000 milliseconds for 15 seconds
            });
            this.spinner.hide();
            this.router.navigate(['/ESSPortal/performance-reviews-list/' ]);
          }
          else if (response.ResponseCode == 999) {
            this.spinner.hide();
            this.toastr.error(response.Message, 'Error');
          }
          else if (response.ResponseCode == 217) {
            this.spinner.hide();
            this.toastr.error(response.Message, 'Error');
          }
        },
        error: (error: any) => {
          console.error('Error:', error);
          this.spinner.hide();
        }
      });
    } else {
      // Form is invalid, display error messages
      this.validateAllFormFields(this.PerformanceReviewForm);
      this.toastr.error('All fields are mandatory', 'Error');
      this.spinner.hide();
    }




  }

  save() { 
    this.spinner.show();
    this.workFormSubmitted = false;
    let performanceRevResDetails1 = [];
    let goalsList1 = [];
    let keyArray = Object.keys(this.PerformanceReviewForm.value);

    // Extract goals data from the form
    for (let i = 1; i <= 3; i++) {

      let goalKey = 'goal' + i;
      let outcomeKey = 'expectedOutcomes' + i;
      let achievedDateKey = 'achievedDate' + i;
      let goal = this.PerformanceReviewForm.value[goalKey];
      let outcome = this.PerformanceReviewForm.value[outcomeKey];
      let achievedDate = this.PerformanceReviewForm.value[achievedDateKey];

      // Only add to goalsList if goal is not empty
      // if (goal?.trim() !== '') {
      goalsList1.push({
        GoalSequence: i, // Set the sequence number here
        Goal: goal,
        ExpOutcome: outcome,
        AchievebyDate: achievedDate,
        EmployeeId: this.userData.ID
      });
      // }
      // else {
      //   // this.spinner.hide();
      // }
    }

    keyArray.forEach((key: string) => {
      let exist = this.allQuestionData.find((data: any) => data.ID === key);
      if (exist) {
        let controlValue = this.PerformanceReviewForm.get(key)?.value;


        if (controlValue == null) {
          controlValue = ''; // Set it to an empty string
        } else {
          // Ensure controlValue is a string
          if (typeof controlValue !== 'string') {
            // If controlValue is not a string, convert it to a string
            controlValue = String(controlValue);
          }
        }
        // // Ensure controlValue is a string
        // if (typeof controlValue !== 'string') {
        //   // If controlValue is not a string, convert it to a string
        //   controlValue = String(controlValue);
        // }
        let controlRatingValue = key + 'ratting'; // Corrected typo from 'ratting' to 'rating'

        let ratingValue = String(this.PerformanceReviewForm.value[controlRatingValue]);
        // Ensure ratingValue is a string
        if (typeof ratingValue !== 'string') {
          // If ratingValue is not a string, convert it to a string
          ratingValue = String(ratingValue);
        }
        if(exist.Category.Label !== "Feedback"){
        let detail = {
          Question: exist.Question,
          Answer: controlValue,
          RatingEnabled: exist.RatingEnabled,
          Sequence: exist.Sequence,
          Category: {
            Value: exist.Category.Value.toString() // Convert to string using toString() method
          },

          Rating: {
            Value: ratingValue
          },
          LinkedReviewFY: {
            ID: exist.LinkedReviewFY.ID
          }
        };
        performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
      }

      if(exist.Category.Label == "Feedback"){
        let detail = {
          Question: exist.Question,
          FeedbackResponse: controlValue,
          RatingEnabled: exist.RatingEnabled,
          Sequence: exist.Sequence,
          Category: {
            Value: exist.Category.Value.toString() // Convert to string using toString() method
          },

          Rating: {
            Value: ratingValue
          },
          LinkedReviewFY: {
            ID: exist.LinkedReviewFY.ID
          }
        };
        performanceRevResDetails1.push(detail); // Push detail to performanceRevResDetails1
      }


        // No need to construct the final object inside the loop

      }
      // else {
      //   this.spinner.hide();
      // }
    });

    let object: any = {
      EmployeeId: this.userData.ID,
      Managers: this.PerformanceReviewForm.value.Managers,
      ReportingManId: null,
      Projects: this.PerformanceReviewForm.value.Projects,
      LinkedReviewFY: this.PerformanceRevId,
      OverallSelfRating: {
        Value: this.PerformanceReviewForm.value.rating
      },
      Status: {
        Value: "1"
      },
      PerformanceRevResDetails: performanceRevResDetails1,// Assuming there's no condition for PerformanceRevResDetails
      goalsList: goalsList1
    };

    // Send object to API after loop
    this.PerformanceReviewService.SavePerfReviewResponse(object).subscribe({
      next: (response: any) => {
        if (response.ResponseCode == 100) {
          this.responseData = response.Data
          this.toastr.success('Your Performance Review record is saved as DRAFT and no action will be taken till you complete it and SUBMIT the form. Please complete it ASAP. ', 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 15000 // Set to 15000 milliseconds for 15 seconds
          });
          this.router.navigate(['/ESSPortal/performance-reviews-list']);
         
          this.spinner.hide();

        }
        else if (response.ResponseCode == 999) {
          this.spinner.hide();
          this.toastr.error(response.Message, 'Error');
        }
        else if (response.ResponseCode == 217) {
          this.spinner.hide();
          this.toastr.error(response.Message, 'Error');
        }
      },
      error: (error: any) => {
        console.error('Error:', error);
        this.spinner.hide();
      }
    });
  }
  // Function to validate all form fields
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    this.spinner.hide()
  }
  modelValidate() {
    this.workFormSubmitted = true
    if (this.PerformanceReviewForm.invalid) {

      // Form is invalid, display error messages
      this.validateAllFormFields(this.PerformanceReviewForm);
      this.spinner.hide();
      this.toastr.error('All fields are mandatory', 'Error');
    }
  }
}