import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private router: Router,
    private tostr: ToastrService
  ) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('authToken');
    const tokenExpiry = localStorage.getItem('tokenExpiry');

    // Check if token and expiry exist
    if (token && tokenExpiry) {
      const expiryTime = new Date(tokenExpiry).getTime();
      const currentTime = new Date().getTime();

      // If token has expired, log the user out and redirect to login
      if (currentTime >= expiryTime) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('tokenExpiry');
        this.tostr.info('Session Time Out Please Login Again', 'Info');

        // Delay of 5 seconds (5000 milliseconds) before navigating to the login page
        setTimeout(() => {
          this.router.navigate(['/login']); // Redirect to login page
        }, 5000);
        

        return next.handle(req); // You could return EMPTY here if you don't want to continue the request
      }

      // If token is valid, clone the request and add the authorization header
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });

      return next.handle(cloned);
    } else {
      // If no token or expiry, proceed with the request
      return next.handle(req);
    }
  }

}
