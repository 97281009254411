import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';

type NewType = ToDosDataModal;
@Component({
  selector: 'app-update-to-doos',
  templateUrl: './update-to-doos.component.html',
  styleUrls: ['./update-to-doos.component.scss']
})
export class UpdateToDoosComponent implements OnInit {
  supportReqObj: SupportRequestDataModel;
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: NewType;
  solzStatusList: Array<OptionSetDataModel> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  downloadFileList: Array<UploadFileDataModel> = [];
  updateToDoId: string = "";
  lockStartDate: boolean = false; // used for locking start date form field if not having null or null date 
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList = [];
  srNumberItemno: string;

  // used for Asign Me As Option Set.
  assignMeList: any[] = [];
  assign: any;
  // used for work logs list
  workLogsList: Array<WorkLogDataModel> = [];
  showHideBugGridFlag: boolean = false;
  // for update form
  lockFields: boolean = false;
  implementationEffortFlag: boolean;
  expectedDateFlag: boolean;
  linkedTaskFlag: boolean = false;
  bugListData: any;
  edit: string;

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateToDoId = this.route.snapshot.paramMap.get('id');
    }

    this.supportReqForm = this.fb.group({
      ID: [''],
      Name: [''],
      createdon: [''],
      expectedResDate: [null],
      createdOn: [null],
      ItemId: [''],
      itemNo: [''],
      title: ['', [Validators.required]],
      projectName: [''],
      projectId: [null, [Validators.required]],
      solzStatus: [null, [Validators.required]],
      customerStatus:[null, [Validators.required]],
      itemDescription: ['', [Validators.required]],
      reproSteps: [''],
      parentId: [null],
      parentItemNo: [''],
      parentTitle: [''],
      userPriority: [null, [Validators.required]],
      typeOfItem: [null, [Validators.required]],
      workArea: [null],
      isClientReported: ["false", [Validators.required]],
      releaseNumber: [''],
      assigneeId: [null],
      assigneeName: [''],
      assignedReviewerId: [null],
      assignedReviewerName: [''],
      newEmpId: [''],
      newEmpName: [''],
      implementationeffort: [null],
      revieweffort: [null],
      comment: [''],
      createdBy: [''],
      createdByContact: [''],
      CreatedBy: [''],
      startDate: [null],
      testingEnvironment: [null],
      dateMoved: [null],
      descriptionOfResolution: [''],
      isBug: ["false", [Validators.required]],
      sprintName: [null],
      sprintId: [null],
      linkedTaskName: [null],
      linkedTask: [null],
      userStoryTitleAndName:[''],
      linkedTaskTitleAndItemNo:[''],

    });

    this.getData(); // getting data from local storage api and setting it in our local variable.
       //get data from local storage (work status , testing environment, priority , Emp projects by emp Id)
       
       this.itemTypeList = JSON.parse(localStorage.getItem('status'));
       this.testingEnvironmentList = JSON.parse(localStorage.getItem('setEnvironment'));
       this.userPriorityList = JSON.parse(localStorage.getItem('setPriority'));
       this.allProjectList = JSON.parse(localStorage.getItem('setempProjects'));
       this.supportReqForm.patchValue({
         customerStatus: 100000000,
       });
  }

  //*********************** Get APIs Begin *****************************
  //********************* To Dos Begin********************

  async getData() {

    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;
    this.lockStartDate = false;
    this.lockFields = false;
    this.implementationEffortFlag = false;
    this.expectedDateFlag = false;
    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {

      this.empData = empNewRequest;

      // If Contact User is logged in
      if (this.empData.contact) {

        this.custToDosObj = new ToDosDataModal();
        this.contactLoggedIn = true;
        this.contactId = this.empData.contact?.ID;
        // this.getCustomerProjects();
        this.getToDosDataToUpdate()
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // for To Do update.
  async getToDosDataToUpdate() {
    this.supportReqObj = new SupportRequestDataModel();
    this.assignMeList = [{ Label: "Assignee", Value: 0 }, { Label: "Assigned Reviewer", Value: 1 }];
    await this.supportReqService.getToDosDetailsForUpdation(this.updateToDoId, this.contactLoggedIn).subscribe((toUpRes: any) => {

      if (toUpRes.ResponseCode == 100) { // success 

        this.supportReqObj = toUpRes.Data;
        // filling form fields.
        this.srNumberItemno = this.supportReqObj.itemNo
        
          this.supportReqForm.patchValue({
          ID: this.supportReqObj.ID,
          ItemId: this.supportReqObj.ItemId,
          Name: this.supportReqObj.Name,
          assignedReviewerId: this.supportReqObj.assignedReviewerId,
          assignedReviewerName: this.supportReqObj.assignedReviewerName,
          assigneeId: this.supportReqObj.assigneeId,
          assigneeName: this.supportReqObj.assigneeName,
          comment: this.supportReqObj.comment,
          descriptionOfResolution: this.supportReqObj.descriptionOfResolution,
          implementationeffort: this.supportReqObj.implementationeffort,
          isClientReported: this.supportReqObj.isClientReported.toString(),
          itemDescription: this.supportReqObj.itemDescription,
          itemNo: this.supportReqObj.itemNo,
          projectId: this.supportReqObj.projectId,
          projectName: this.supportReqObj.projectName,
          reproSteps: this.supportReqObj.reproSteps,
          revieweffort: this.supportReqObj.revieweffort,
          startDate: this.supportReqObj.startDate,
          solzStatus: this.supportReqObj.solzStatus?.Value,
          customerStatus:this.supportReqObj.customerStatus?.Value,
          testingEnvironment: this.supportReqObj.testingEnvironment?.Value,
          title: this.supportReqObj.title,
          typeOfItem: this.supportReqObj.typeOfItem.Value,
          userPriority: this.supportReqObj.userPriority?.Value,
          workArea: this.supportReqObj.workArea?.Value,
          parentTitle: this.supportReqObj.parentTitle,
          parentId: this.supportReqObj.parentId,
          parentItemNo: this.supportReqObj.parentItemNo,
          isBug: this.supportReqObj.isBug,
          sprintName: this.supportReqObj.sprintName,
          sprintId: this.supportReqObj.sprintId,
          linkedTask: this.supportReqObj.linkedTask,
          linkedTaskName: this.supportReqObj.linkedTaskName,
          linkedTaskId: this.supportReqObj.linkedTask,
          linkedTaskTitleAndItemNo:this.supportReqObj.linkedTaskTitleAndItemNo,
          userStoryTitleAndName:this.supportReqObj.userStoryTitleAndName


        });
        this.userStoryList.push(this.supportReqObj.parentItemNo)

        this.userStoryList.push(this.supportReqObj.parentItemNo);
        
        if(this.supportReqObj.userStoryTitleAndName==null){
          this.supportReqForm.patchValue({
            userStoryTitleAndName:'User Story Not Found'
          })
        }
        else{
          this.supportReqForm.patchValue({
            userStoryTitleAndName:this.supportReqObj.userStoryTitleAndName
          })
        }
        if(this.supportReqObj.linkedTaskTitleAndItemNo==null){
          this.supportReqForm.patchValue({
            linkedTaskTitleAndItemNo:'Linked Task Not Found'
          })
        }
        this.GetTodosByLinkedTaskIdOnBug(this.supportReqObj.ItemId);
        if (this.supportReqForm.value.solzStatus != 674180000 && this.supportReqObj?.isBug === false) {
          this.showHideBugGridFlag = true;
          // this.showHideWorklogFlag = true;
        }
        else {
          this.showHideBugGridFlag = false;
        }

        // making sure correct data is displayed in the form
        if (this.supportReqForm.value.testingEnvironment == null) {
          this.supportReqForm.patchValue({
            testingEnvironment: null
          });
        }
        // format of expected resolution date so as to display in form for both Employee and Contact
        if (this.supportReqObj.expectedResDate != null) {
          var erd = this.supportReqObj.expectedResDate.split("-");

          var date = erd[2] + '-' + erd[1] + '-' + erd[0];
          this.supportReqForm.patchValue({
            expectedResDate: date
          });
        }


        if (this.supportReqForm.value.isClientReported) {
          this.supportReqForm.value.isClientReported = "true";
        }

        else {
          this.supportReqForm.value.isClientReported = "false";
        }

        if (this.supportReqForm.value.solzStatus == 100000000) {
          this.supportReqForm.patchValue({
            solzStatus: null,
          });
        }

        // locking fields according to specific conditions
        if (!this.contactLoggedIn) {
            if (this.supportReqForm.value.solzStatus != null) {
            if (this.supportReqForm.value.solzStatus == 674180007) {
              this.lockFields = true;
              this.toastr.info("This Task is already deployed, you can't add or modify any field(s)", 'Information', {
                positionClass: 'toast-top-full-width',
                timeOut: 5000
              });
            }
          }

          if (this.supportReqForm.value.implementationeffort) {
            this.implementationEffortFlag = true;
          }

          if (this.supportReqForm.value.implementationeffort == 0) {
            this.supportReqForm.value.implementationeffort == null
          }

          if (this.supportReqForm.value.revieweffort) {
            this.reviewerEffortfieldFlag = true;
          }

          if (this.supportReqForm.value.revieweffort == 0) {
            this.supportReqForm.value.revieweffort = null;
          }

          if (this.supportReqForm.value.expectedResDate != null) {
            this.expectedDateFlag = true;
          }

          // format of expected resolution date so as to display in form for Employee.
          if (this.supportReqForm.value.startDate != "0001-01-01T00:00:00") {
            this.supportReqForm.value.startDate = moment(this.supportReqForm.value.startDate).format('YYYY-MM-DD');
            this.supportReqForm.patchValue({
              startDate: this.supportReqForm.value.startDate
            });
            this.lockStartDate = true;
          }

          else {
            this.supportReqForm.value.startDate = null;
          }
          this.spinner.hide();
        }
        this.spinner.hide();
      }
     

      else if (toUpRes.ResponseCode == 999) {
        this.toastr.error(toUpRes.Message, 'Error');
        this.spinner.hide();

      }

      this.getToDsAttachments();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  async getToDsAttachments() {
    this.downloadFileList = [] as Array<UploadFileDataModel>;
    await this.supportReqService.getToDosAttachmentsFromCRMSharepoint(this.updateToDoId).subscribe((fileRes: any) => {
      if (fileRes.ResponseCode == 100) {// success
        this.downloadFileList = fileRes.Data;
        this.spinner.hide();
      }

      else if (fileRes.ResponseCode == 999) {
        // this.toastr.error(fileRes.Message, 'Error');
        this.spinner.hide();

      }
      // this.getWorkLogList();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
      this.spinner.hide();
    });
  }

  //********************* To Dos End ********************

  //*********************** Get APIs End *****************************

  //****************************** To-Do Related Actions Begin *************************


  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }

  /*On Change of solz status i.e if solz status is  "Not Started" or "Analyzing" or "On Hold" or "Duplicate" or "QA Failed" or "Canceled" than ETA and Estimated Effort is not Required, else Bussiness Required.*/
  solzStatusSelected(val: number) {

    // If Employee Logged In.
    if (this.empData.contact == null) {
      if (val == 674180000 || val == 674180009 || val == 674180008 || val == 674180012 || val == 674180010) {
        this.fieldRequiredFlag = false;
      }

      else {
        this.fieldRequiredFlag = true;
      }

      //If Solz status is "Review in progress" or "Review Complete" or "Review Failed" or "Deployed" then change review effort to required or else not required
      if (val == 674180004 || val == 674180005 || val == 674180006 || val == 674180007) {
        this.reviewerEffortfieldFlag = true;
      }

      else {
        this.reviewerEffortfieldFlag = false;
      }

      //If "Ready for Review" then flush testing environment
      if (val == 674180003) {
        this.supportReqForm.patchValue({
          testingEnvironment: null
        });
      }
    }
  }

  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) { 
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
          // Check if the file with the same name already exists in the array
      const fileExists = this.upLoadFileList.some((uploadedFile) => uploadedFile.filename === file.name);
      if (fileExists) {
        this.toastr.error("File with the same name already exists");
        setTimeout(() => {
          this.toastr.clear();
        },3000);
        continue; // Skip this file and move on to the next one
      }
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }
  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);
  
    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  onSubmit() {

    this.spinner.show();
    this.supportReqFormSubmitted = true;
    if (this.fieldRequiredFlag == true || this.reviewerEffortfieldFlag == true) {
      if (this.supportReqForm.value.startDate == null || this.supportReqForm.value.startDate == "0001-01-01T00:00:00") {
        this.supportReqForm.controls['startDate'].setErrors({ 'invalid': true });
      }

      if ((this.supportReqForm.value.implementationeffort == null || this.supportReqForm.value.implementationeffort == 0) && this.fieldRequiredFlag == true) {
        this.supportReqForm.controls['implementationeffort'].setErrors({ 'invalid': true });
      }

      if ((this.supportReqForm.value.revieweffort == null || this.supportReqForm.value.revieweffort == 0) && this.reviewerEffortfieldFlag == true) {
        this.supportReqForm.controls['revieweffort'].setErrors({ 'invalid': true });
      }
    }

    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      return;
    }

    var st = this.supportReqForm.value.solzStatus;
    this.supportReqForm.value.solzStatus = new Object();
    this.supportReqForm.value.solzStatus.Value = st;
    //for customer Status field data 
    var cst = this.supportReqForm.value.customerStatus;
    this.supportReqForm.value.customerStatus = new Object();
    this.supportReqForm.value.customerStatus.Value = cst;

    var isr = this.supportReqForm.value.isClientReported;
    this.supportReqForm.value.isClientReported = JSON.parse(isr);

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.isBug;
    this.supportReqForm.value.isBug = JSON.parse(ib);

    if (this.supportReqForm.value.testingEnvironment != null) {
      var te = this.supportReqForm.value.testingEnvironment;
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = te;
    }

    else {
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = 674180004
    }

    if (this.supportReqForm.value.startDate != null) {
      var date = new Date(this.supportReqForm.value.startDate);
      var sd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.startDate = sd;
    }

    if (this.supportReqForm.value.expectedResDate != null) {
      var erd = this.supportReqForm.value.expectedResDate;
      this.supportReqForm.value.expectedResDate = moment(erd).format('YYYY-MM-DD');
    }

    if (!this.contactLoggedIn) {
      this.supportReqForm.value.createdBy = this.empId;
      this.supportReqForm.value.CreatedBy = this.empId;
    }

    if (this.assign?.length == 1) {
      if (this.assign[0] == 0) {
        this.supportReqForm.value.assigneeId = this.empId;
      }
      else if (this.assign[0] == 1) {
        this.supportReqForm.value.assignedReviewerId = this.empId;
      }
    }

    else if (this.assign?.length == 2) {
      this.supportReqForm.value.assigneeId = this.empId
      this.supportReqForm.value.assignedReviewerId = this.empId;
    }

    // deciding which object to send according to who logged in i.e., 

    var updateSupportReqObj;
    if (this.contactLoggedIn) {

      updateSupportReqObj = {
        ID: this.supportReqForm.value.ID,
        comment: this.supportReqForm.value.comment,
        ItemId: this.supportReqForm.value.ItemId,
        itemNo: this.supportReqForm.value.itemNo,
        projectId: this.supportReqForm.value.projectId,
        projectName: this.supportReqForm.value.projectName,
        // solzStatus: this.supportReqForm.value.solzStatus
        customerStatus:this.supportReqForm.value.customerStatus,
        isBug:this.supportReqForm.value.isBug,
      }
    }
    else {
      updateSupportReqObj = this.supportReqForm.value;
    }

    //else if (this.supportReqForm.value.newEmpId == 0 )
    this.toDosService.updateToDo(updateSupportReqObj).subscribe((updateRes: any) => {

      this.spinner.hide();
      if (updateRes.ResponseCode == 100) { // success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = this.updateToDoId;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }

        else { // else just show message.
          this.spinner.hide();
          this.toastr.success('Success', 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          this.router.navigate(['/ESSPortal/customer/my_to_doos']);

        }
      }

      else if (updateRes.ResponseCode == 999) {
        this.toastr.error(updateRes.Message, 'Error');
        this.spinner.hide();

      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });


  }
  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
    await this.supportReqService.postUploadFilesWithToDos(attachFiles).subscribe((upRes: any) => {
      if (upRes.ResponseCode == 100) { // success
        this.toastr.success(upRes.Message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        this.router.navigate(['/ESSPortal/customer/my_to_doos']);
      }

      else if (upRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(upRes.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // async uploadFiles(attachFiles: any) {
  //   await this.supportReqService.postUploadFilesWithToDo(attachFiles).subscribe((upRes: any) => {
  //     if (upRes.ResponseCode == 100) { // success
  //       this.toastr.success(upRes.Message, 'Success', {
  //         positionClass: 'toast-top-full-width',
  //         timeOut: 5000
  //       });
  //       this.router.navigate(['/ESSPortal/customer/my_to_doos']);
  //     }

  //     else if (upRes.ResponseCode == 999) {
  //       this.spinner.hide();
  //       this.toastr.error(upRes.Message);
  //     }

  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error(error.message);
  //   });
  // }

  createNewTodo() {
    this.router.navigate(['/ESSPortal/customer/new_to_doos']);
  }

  download(file: UploadFileDataModel) {
    import("file-saver").then(FileSaver => {
      var contentbase64 = file.bytes;
      var contenttype = file.filetype;
      var fileFormat = "data:" + contenttype + ";base64," + contentbase64;
      //download file
      FileSaver.saveAs(fileFormat, file.filename);
    });

  }
  GetTodosByLinkedTaskIdOnBug(linkedTaskAddId) {
    this.supportReqService.GetTodosByLinkedTaskIdOnBug(linkedTaskAddId).subscribe((bugResp: any) => {
      if (bugResp.ResponseCode == 100) { // success
        this.bugListData = bugResp.Data;
      }

      else if (bugResp.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(bugResp.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }
   //send Id's of These Fields on router
   addBug() {
    this.router.navigate(['/ESSPortal/to_dos/to-dos-bugs/', this.updateToDoId, '/' + this.supportReqForm.value.projectId,
      '/' + this.supportReqObj.parentId, '/' + this.supportReqObj.title, '/' + this.supportReqObj.isBug, '/' + this.edit, '/'
      + this.supportReqObj.assigneeId, '/' + this.supportReqObj.assignedReviewerId,
      '/' + this.supportReqObj.itemNo, '/' + this.supportReqObj.testingEnvironment.Value]);

  }

    //Task Bug's grid list record update 
    updateLinkedBug(recordId) {
      this.updateToDoId = recordId
      this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.updateToDoId]);
      this.getToDosDataToUpdate()
    }

}
