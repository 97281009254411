import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PerformanceReviewService {
  apiURL: string = "";
  authapiURL: string = "";

  constructor(
    public HttpClient: HttpClient,
  ) {
    this.apiURL = environment.apiURL
    this.authapiURL = environment.authapiURL
  }
  // GetGoalListsById(id: any) {
  //   return this.HttpClient.get(this.apiURL + 'PerformanceReview/GetcurrFinYearGoalsbyId/' + id)
  // }
  GetGoalListsById() {
    return this.HttpClient.get(this.authapiURL + 'PerformanceAndGoals/GetMyGoalsListByEmpId')
  }

  // postgetgoalsDetailsUpdation(obj:any): Observable<any> {     
  //   return this.HttpClient.get(this.apiURL + "PerformanceReview/EditGoalDetails/" , obj );
  // }
  postgetgoalsDetailsUpdation(obj: any) {
    return this.HttpClient.post(this.authapiURL + 'PerformanceAndGoals/EditGoalDetails', obj);
  }

  //Post API'S Of Create Condiate Application Goals
  postaddEmployeeGoals(obj: any): Observable<any> {
    return this.HttpClient.post(this.authapiURL + 'PerformanceAndGoals/SaveGoalDetails', obj);
  }

  // getting option sets for new and edit Todos Page by sending type of list string.
  getDropDownDataOfStatus() {
    return this.HttpClient.get(this.apiURL + 'SupportRequest/SetDropdownforCRM?dropDownOf=statuscode');
  }

  //########################################Performance Review Form API'S Start##################################

 // getting option sets for new and edit Todos Page by sending type of list string.
 getDropDownRatingReviewStatus() {
  return this.HttpClient.get(this.apiURL + '/SupportRequest/SetDropdownforCRM?dropDownOf=solz_overallselfrating');
}
  GetPerfReviewQuetions(prfrefId:any) {
    return this.HttpClient.get(this.apiURL + 'PerformanceReview/GetPerfReviewQuetions?id='+ prfrefId)
  }

  // GetReviewResponseforLoggedInEmployee( empId:any , prfId ) {
  //   return this.HttpClient.get(this.apiURL + "PerformanceReview/GetReviewResponseforLoggedInEmployee?id=" + empId +'&performanceRevYearId=' + prfId)
  // }
  GetReviewResponseforLoggedInEmployee( prfId ) {
    return this.HttpClient.get(this.authapiURL + "PerformanceAndGoals/GetReviewResponseforLoggedInEmployee?PerformanceRevYearId=" + prfId)
  }


   //Post API'S Of Create review form Application 
   SavePerfReviewResponse(obj: any): Observable<any> {
    return this.HttpClient.post(this.apiURL + 'PerformanceReview/SavePerfReviewResponse', obj);
  }

  //EMp ID Bind
  GetAllPerformanceReviewList(){
    return this.HttpClient.get(this.authapiURL + 'PerformanceAndGoals/GetAllPerformanceReviewList' )
  }
 

}

