import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { EmployeeRecordService } from '../../_services/employee-record.service';

@Component({
  selector: 'app-my-hiring-application',
  templateUrl: './my-hiring-application.component.html',
  styleUrls: ['./my-hiring-application.component.scss']
})
export class MyHiringApplicationComponent implements OnInit {
  CandidateApplication: any;
  employeId: string;

  constructor(
    public toastr: ToastrService,
    private spinner : NgxSpinnerService,
    private employeeRecordService: EmployeeRecordService,

  ) {
    this.spinner.hide();
   }

  ngOnInit(): void {
   
    this.spinner.show();
    let empId = localStorage.getItem('empId');
    this.employeId = empId
    this.getCandidateApplicationByEmployeeId()
  }
  getCandidateApplicationByEmployeeId() {
    this.employeeRecordService.getCandidateApplicationByEmployeeId().subscribe((Responce) => {
      if (Responce.isSuccessful == true) {
        this.CandidateApplication = Responce.data;
        this.spinner.hide();
      }
      else if (Responce.isSuccessful == false) {
        this.spinner.hide();
        if(Responce?.messageDetail?.message_code != 204){

          this.toastr.error(Responce?.messageDetail?.message, 'Error');
        }
      }
  
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.messageDetail?.message);
    })

  }
}
