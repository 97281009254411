import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EmployeeDataModel } from '../../../_models/employee-data-model';
import { OptionSetDataModel } from '../../../_models/option-set-data-model';
import { ProjectDataModel } from '../../../_models/projects-data-model';
import { SupportRequestDataModel } from '../../../_models/support-request-data-model';
import { ToDosDataModal } from '../../../_models/to-dos-data-model';
import { UploadFileDataModel } from '../../../_models/upload-file-data-model';
import { WorkLogDataModel } from '../../../_models/work-log-data-model';
import { SupportRequestService } from '../../../_services/support-request.service';
import { ToDosService } from '../../../_services/to-dos.service';

type NewType = ToDosDataModal;

@Component({
  selector: 'app-update-to-dos',
  templateUrl: './update-to-dos.component.html',
  styleUrls: ['./update-to-dos.component.css']
})
export class UpdateToDosComponent implements OnInit {
  supportReqObj: SupportRequestDataModel;
  empData: EmployeeDataModel;
  itemTypeList: Array<OptionSetDataModel> = [];
  userPriorityList: Array<OptionSetDataModel> = [];
  testingEnvironmentList: Array<OptionSetDataModel> = [];
  allProjectList: Array<ProjectDataModel> = [];
  supportReqForm: FormGroup;
  parentToDosList: Array<OptionSetDataModel> = [];
  startDateFlag: boolean = false;
  endDateFlag: boolean = false;
  fieldRequiredFlag: boolean = false;
  reviewerEffortfieldFlag: boolean = false;
  contactLoggedIn: boolean = false;
  contactId: string = "";
  empId: string = "";
  datasplit: any;
  isManager: any;
  custToDosObj: ToDosDataModal;
  empToDosObj: NewType;
  solzStatusList: Array<OptionSetDataModel> = [];
  typeOfItemList: Array<OptionSetDataModel> = [];
  allEmployeeDetails: Array<EmployeeDataModel> = [];
  supportReqFormSubmitted: boolean = false;
  upLoadFileList: Array<UploadFileDataModel> = [];
  downloadFileList: Array<UploadFileDataModel> = [];
  updateToDoId: string = "";
  lockStartDate: boolean = false; // used for locking start date form field if not having null or null date 
  sprintsList: Array<OptionSetDataModel> = [];
  projectSprint: any;
  userStoryList = [];
  linkedTaskFlag: boolean = false;

  // used for Asign Me As Option Set.
  assignMeList: any[] = [];
  assign: any;
  // used for work logs list
  workLogsList: Array<WorkLogDataModel> = [];
  showHideWorklogFlag: boolean = false;
  showHideBugGridFlag: boolean = false;

  // for update form
  lockFields: boolean = false;
  implementationEffortFlag: boolean;
  expectedDateFlag: boolean;
  projectName: string;
  userStory: any;
  isBugCheck: any;
  updateToDoisBug: string;
  updateToDouserPriority: string;
  updateToDoprojectName: string;
  updateToDosprintName: string;
  isBugTodos: any;
  updateToDolinkedTaskName: string;
  bugTodoId: any;
  showBugButtonFlag: boolean = false;
  bugListData: any;
  edit: string = null;
  srNumberItemno: string;
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  // searchFilterVal: any;
  viewImageSrc = null;
  tempImgArray = [];

  constructor(public toDosService: ToDosService,
    private storage: StorageMap,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public supportReqService: SupportRequestService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2, private elRef: ElementRef) { }

  ngOnInit(): void {

    this.spinner.show();
    if (this.route.snapshot.paramMap.get('id')) {
      this.updateToDoId = this.route.snapshot.paramMap.get('id');
      this.updateToDoprojectName = this.route.snapshot.paramMap.get('projectName');
      this.updateToDouserPriority = this.route.snapshot.paramMap.get('userPriority');
      this.updateToDolinkedTaskName = this.route.snapshot.paramMap.get('linkedTaskName');
      this.updateToDoisBug = this.route.snapshot.paramMap.get('isBug');
      this.updateToDoisBug = this.route.snapshot.paramMap.get('assignee');
      this.updateToDoisBug = this.route.snapshot.paramMap.get('reviewer');
      this.updateToDoisBug = this.route.snapshot.paramMap.get('testingEnv');

    }
    this.supportReqForm = this.fb.group({
      ID: [''],
      Name: [''],
      createdon: [''],
      expectedResDate: [null],
      endDate: [null],
      createdOn: [null],
      ItemId: [''],
      itemNo: [''],
      title: ['', [Validators.required]],
      projectName: [''],
      projectId: [null, [Validators.required]],
      solzStatus: [null, [Validators.required]],
      itemDescription: ['', [Validators.required]],
      reproSteps: [''],
      parentId: [null],
      parentItemNo: [''],
      parentTitle: [''],
      userPriority: [null, [Validators.required]],
      typeOfItem: [null, [Validators.required]],
      workArea: [null],
      isClientReported: ["false", [Validators.required]],
      releaseNumber: [''],
      assigneeId: [null],
      assigneeName: [''],
      assignedReviewerId: [null],
      assignedReviewerName: [''],
      newEmpId: [''],
      newEmpName: [''],
      implementationeffort: [null],
      revieweffort: [null],
      comment: [''],
      createdBy: [''],
      createdByContact: [''],
      CreatedBy: [''],
      startDate: [null],
      testingEnvironment: [null],
      dateMoved: [null],
      descriptionOfResolution: [''],
      isBug: ["true", [Validators.required]],
      sprintName: [null],
      sprintId: [null],
      // linkedTask:[, [Validators.required]],
      linkedTaskName: [''],
      // ItemId:['']
      userStoryTitleAndName: [''],
      linkedTaskTitleAndItemNo: [''],

    });

    this.getData(); // getting data from local storage api and setting it in our local variable.


    this.solzStatusList = JSON.parse(localStorage.getItem('setWorkStatus'));
    this.testingEnvironmentList = JSON.parse(localStorage.getItem('setEnvironment'));
    this.userPriorityList = JSON.parse(localStorage.getItem('setPriority'));
    this.allProjectList = JSON.parse(localStorage.getItem('setempProjects'));
    this.supportReqForm.patchValue({
      solzStatus: 674180000,
      userPriority: this.userPriorityList[2].Value,
      // projectId: this.allProjectList[0].ID,
    });
    // this.toDosService.latestStateInspection.subscribe(resp => {
    //   if (resp) {
    //     this.searchFilterVal = resp
    //     console.log("resp2", resp)
    //   }
    // });
    const ce = this.elRef.nativeElement.querySelector('#commentDiv');
    document.getElementById('commentDiv').addEventListener(
      'keydown', (e: KeyboardEvent) => {
        if (!((e.ctrlKey && (e.key == 'v' || e.key == 'V')) || e.code == 'Backspace')) e.preventDefault();
      }
    )
    // this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
    //   e.preventDefault();
    //   document.getElementById('commentDiv').innerHTML += ' ';
    //   if (e.clipboardData && e.clipboardData.items.length) {
    //     let clipboardItems = e.clipboardData.items;
    //     for (let i = 0; i < clipboardItems.length; i++) {
    //       const item = clipboardItems[i];

    //       if (item.type.indexOf('image') !== -1) {
    //         const file = item.getAsFile();
    //         let fileReader = new FileReader();
    //         if (file) {
    //           let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

    //           fileReader.onloadend = () => {
    //             const base64 = fileReader.result as string;
    //             let data = {
    //               fileName: fileName,
    //               base64: base64
    //             };

    //             this.tempImgArray.push(data)

    //           }


    //           if (file) {
    //             fileReader.readAsDataURL(file);
    //           }
    //           // Create button element
    //           const button = this.renderer.createElement('a');
    //           button.setAttribute('style', 'cursor: pointer; color: blue'); // Ensuring button type is button
    //           button.setAttribute('data-toggle', 'modal'); //data-toggle="modal" data-target="#moreInfoModal1"
    //           button.setAttribute('data-target', '#viewImage');
    //           this.renderer.setAttribute(button, 'contenteditable', 'false'); // Make button non-editable
    //           let element = document.getElementById('viewImage');
    //           this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
    //           const icon = this.renderer.createElement('i');
    //           this.renderer.addClass(icon, 'fa-regular');
    //           this.renderer.addClass(icon, 'fa-image');
    //           this.renderer.addClass(icon, 'mx-2');
    //           const text = this.renderer.createText(fileName);

    //           this.renderer.appendChild(button, icon);
    //           this.renderer.appendChild(button, text);
    //           this.renderer.appendChild(ce, button);
    //         }
    //       }
    //       if (this.tempImgArray) {
    //         this.selectAttachments(this.tempImgArray)
    //       }

    //       // if (item.type.indexOf('text') !== -1) {
    //       //   const text = e.clipboardData.getData('text/plain');
    //       //   document.execCommand('insertText', false, text);
    //       // }
    //     }
    //   }
    // });
    this.renderer.listen(ce, 'paste', (e: ClipboardEvent) => {
      e.preventDefault();
      document.getElementById('commentDiv').innerHTML += ' ';

      if (e.clipboardData && e.clipboardData.items.length) {
        let clipboardItems = e.clipboardData.items;
        let files: File[] = []; // To collect all files (images) from the clipboard

        for (let i = 0; i < clipboardItems.length; i++) {
          const item = clipboardItems[i];

          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            let fileReader = new FileReader();

            if (file) {
              files.push(file); // Add the file to the files array

              let fileName = `${file.name.split('.')[0]}_${moment().format('DDmmYYYYHHMMSS')}.${file.name.split('.')[1]}`;

              fileReader.onloadend = () => {
                const base64 = fileReader.result as string;
                let data = {
                  fileName: fileName,
                  base64: base64
                };

                this.tempImgArray.push(data);
              }

              fileReader.readAsDataURL(file);

              // Create button element
              const button = this.renderer.createElement('a');
              button.setAttribute('style', 'cursor: pointer; color: blue');
              button.setAttribute('data-toggle', 'modal');
              button.setAttribute('data-target', '#viewImage');
              this.renderer.setAttribute(button, 'contenteditable', 'false');
              let element = document.getElementById('viewImage');
              this.renderer.listen(button, 'click', () => this.downloadImg(fileName, element));
              const icon = this.renderer.createElement('i');
              this.renderer.addClass(icon, 'fa-regular');
              this.renderer.addClass(icon, 'fa-image');
              this.renderer.addClass(icon, 'mx-2');
              const text = this.renderer.createText(fileName);

              this.renderer.appendChild(button, icon);
              this.renderer.appendChild(button, text);
              this.renderer.appendChild(ce, button);
            }
          }
        }

        // Creating a custom event object for selectAttachments function
        const mockEvent = {
          target: {
            files: files
          }
        };

        if (files.length > 0) {
          this.selectAttachments(mockEvent); // Call selectAttachments with the mock event
        }
      }
    });

  }

  downloadImg(fileName: string, template) {
    this.viewImageSrc = this.tempImgArray.find(img => img.fileName == fileName);
  }

  async downloadPreviewImage() {
    let FileSaver = await import("file-saver");
    FileSaver.saveAs(this.viewImageSrc.base64, this.viewImageSrc.fileName);
  }



  //*********************** Get APIs Begin *****************************
  //********************* To Dos Begin********************

  async getData() {

    // inintialising data here so as to call this function only for updating the to do.
    this.startDateFlag = false;
    this.endDateFlag = false;
    this.fieldRequiredFlag = false;
    this.reviewerEffortfieldFlag = false;
    this.supportReqFormSubmitted = false;
    this.contactLoggedIn = false;
    this.lockStartDate = false;
    this.lockFields = false;
    this.implementationEffortFlag = false;
    this.expectedDateFlag = false;
    this.empData = new EmployeeDataModel();
    this.upLoadFileList = [] as Array<UploadFileDataModel>;
    await this.storage.get('empDetails').subscribe((empNewRequest: EmployeeDataModel) => {

      this.empData = empNewRequest.userProfile;

      // If Soluzione Emp/Manager logged in
      if (this.empData.userId) {

        this.contactLoggedIn = false;
        this.isManager = this.empData?.isManager;
        this.empId = this.empData?.userId
        this.getWorkLogList();
        this.getToDosDataToUpdate()
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  // for To Do update.
  async getToDosDataToUpdate() {
    this.supportReqObj = new SupportRequestDataModel();
    this.assignMeList = [{ Label: "Assignee", Value: 0 }, { Label: "Assigned Reviewer", Value: 1 }];
    this.supportReqService.getToDosDetailsForUpdation(this.updateToDoId, this.contactLoggedIn).subscribe((toUpRes: any) => {

      if (toUpRes.ResponseCode == 100) {   // success 

        this.supportReqObj = toUpRes.Data;

        this.projectName = this.supportReqObj.projectName;
        this.isBugCheck = this.supportReqObj.isBug;
        this.srNumberItemno = this.supportReqObj.itemNo
        // filling form fields.
        this.supportReqForm.patchValue({
          ID: this.supportReqObj.ID,
          ItemId: this.supportReqObj.ItemId,
          Name: this.supportReqObj.Name,
          assignedReviewerId: this.supportReqObj.assignedReviewerId,
          assignedReviewerName: this.supportReqObj.assignedReviewerName,
          assigneeId: this.supportReqObj.assigneeId,
          assigneeName: this.supportReqObj.assigneeName,
          comment: this.supportReqObj.comment,
          endDate: this.supportReqObj.endDate,
          descriptionOfResolution: this.supportReqObj.descriptionOfResolution,
          implementationeffort: this.supportReqObj.implementationeffort,
          isClientReported: this.supportReqObj.isClientReported.toString(),
          itemDescription: this.supportReqObj.itemDescription,
          itemNo: this.supportReqObj.itemNo,
          projectId: this.supportReqObj.projectId,
          projectName: this.supportReqObj.projectName,
          reproSteps: this.supportReqObj.reproSteps,
          revieweffort: this.supportReqObj.revieweffort,
          startDate: this.supportReqObj.startDate,
          solzStatus: this.supportReqObj.solzStatus?.Value,
          testingEnvironment: this.supportReqObj.testingEnvironment?.Value,
          title: this.supportReqObj.title,
          typeOfItem: this.supportReqObj.typeOfItem.Value,
          userPriority: this.supportReqObj.userPriority?.Value,
          workArea: this.supportReqObj.workArea?.Value,
          parentTitle: this.supportReqObj.parentTitle,
          parentId: this.supportReqObj.parentId,
          parentItemNo: this.supportReqObj.parentItemNo,
          isBug: this.supportReqObj.isBug,
          sprintName: this.supportReqObj.sprintName,
          sprintId: this.supportReqObj.sprintId,
          linkedTask: this.supportReqObj.linkedTask,
          linkedTaskName: this.supportReqObj.linkedTaskName,
          linkedTaskId: this.supportReqObj.linkedTask,
          linkedTaskTitleAndItemNo: this.supportReqObj.linkedTaskTitleAndItemNo,
          userStoryTitleAndName: this.supportReqObj.userStoryTitleAndName
        });


        this.userStoryList.push(this.supportReqObj.parentItemNo);
        if (this.supportReqObj.userStoryTitleAndName == null) {
          this.supportReqForm.patchValue({
            userStoryTitleAndName: 'User Story Not Found'
          })
        }
        if (this.supportReqObj.linkedTaskTitleAndItemNo == null) {
          this.supportReqForm.patchValue({
            linkedTaskTitleAndItemNo: 'Linked Task Not Found'
          })
        }
        if (this.supportReqObj.sprintName == null) {
          this.supportReqForm.patchValue({
            sprintName: 'Sprint Name Not Found'
          })
        }

        this.expandLabel(this.isBugCheck);
        this.GetTodosByLinkedTaskIdOnBug(this.supportReqObj.ItemId);

        // making sure correct data is displayed in the form
        if (this.supportReqForm.value.testingEnvironment == null) {
          this.supportReqForm.patchValue({
            testingEnvironment: null
          });
        }
        if (this.supportReqObj.isBug == true && this.supportReqObj.solzStatus?.Value == 674180000) {
          this.supportReqForm.get('itemDescription').enable();
        }
        else {
          this.supportReqForm.get('itemDescription').disable();
        }

        if (this.supportReqForm.value.solzStatus != 674180000 && this.supportReqObj?.isBug === false) {
          this.showHideBugGridFlag = true;
          // this.showHideWorklogFlag = true;
        }
        else {
          this.showHideBugGridFlag = false;
          this.showHideWorklogFlag = false;
        }
        if (this.supportReqForm.value.solzStatus != 674180000) {
          // this.showHideBugGridFlag = true;
          this.showHideWorklogFlag = true;
        }
        else {
          // this.showHideBugGridFlag = false;
          this.showHideWorklogFlag = false;
        }

        // format of expected resolution date so as to display in form for both Employee and Contact
        if (this.supportReqObj.expectedResDate != null) {

          var erd = this.supportReqObj.expectedResDate.split("-");

          var date = erd[2] + '-' + erd[1] + '-' + erd[0];

          this.supportReqForm.patchValue({
            expectedResDate: date
          });
        }


        if (this.supportReqForm.value.isClientReported) {
          this.supportReqForm.value.isClientReported = "true";
        }

        else {
          this.supportReqForm.value.isClientReported = "false";
        }

        if (this.supportReqForm.value.solzStatus == 100000001) {
          this.supportReqForm.patchValue({
            solzStatus: null,
          });
        }

        // locking fields according to specific conditions
        if (!this.contactLoggedIn) {

          if (this.supportReqForm.value.solzStatus != null) {
            if (this.supportReqForm.value.solzStatus == 674180007) {
              this.lockFields = true;
              this.toastr.info("This Task is already deployed, you can't add or modify any field(s)", 'Information', {
                positionClass: 'toast-top-full-width',
                timeOut: 5000
              });
            }
          }

          if (this.supportReqForm.value.implementationeffort) {
            this.implementationEffortFlag = true;
          }

          if (this.supportReqForm.value.implementationeffort == 0) {
            this.supportReqForm.value.implementationeffort == null;
          }

          if (this.supportReqForm.value.revieweffort) {
            this.reviewerEffortfieldFlag = true;
          }

          if (this.supportReqForm.value.revieweffort == 0) {
            this.supportReqForm.value.revieweffort = null;
          }

          if (this.supportReqForm.value.expectedResDate != null) {
            this.expectedDateFlag = true;
          }

          if (this.supportReqForm.value.endDate != "0001-01-01T00:00:00" && this.supportReqForm.value.endDate != null) {
            this.supportReqForm.value.endDate = moment(this.supportReqForm.value.endDate).format('YYYY-MM-DD');
            this.supportReqForm.patchValue({
              endDate: this.supportReqForm.value.endDate
            });
            this.lockStartDate = true;
          }

          // format of expected resolution date so as to display in form for Employee.
          if (this.supportReqForm.value.startDate != "0001-01-01T00:00:00") {
            this.supportReqForm.value.startDate = moment(this.supportReqForm.value.startDate).format('YYYY-MM-DD');
            this.supportReqForm.patchValue({
              startDate: this.supportReqForm.value.startDate
            });
            this.lockStartDate = true;
          }


          else {
            this.supportReqForm.value.startDate = null;
          }
        }
      }

      else if (toUpRes.ResponseCode == 999) {
        this.toastr.error(toUpRes.Message, 'Error');
        this.spinner.hide();

      }

      this.getToDsAttachments();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // async getToDsAttachments() {
  //   this.downloadFileList = [] as Array<UploadFileDataModel>;
  //   await this.supportReqService.getToDosAttachmentsFromCRM(this.updateToDoId).subscribe((fileRes: any) => {
  //     if (fileRes.ResponseCode == 100) {// success
  //       this.downloadFileList = fileRes.Data;
  //     }

  //     else if (fileRes.ResponseCode == 999) {
  //       this.toastr.error(fileRes.Message, 'Error');
  //       this.spinner.hide();

  //     }
  //     this.getWorkLogList();

  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error(error.message);
  //   });
  // }

  //CRM SharePoint API
  async getToDsAttachments() {
    this.downloadFileList = [] as Array<UploadFileDataModel>;
    await this.supportReqService.getToDosAttachmentsFromCRMSharepoint(this.updateToDoId).subscribe((fileRes: any) => {
      if (fileRes.ResponseCode == 100) {// success
        this.downloadFileList = fileRes.Data;
      }

      else if (fileRes.ResponseCode == 999) {
        // if(fileRes.Data != null){q
        //   this.toastr.error(fileRes.Message, 'Error');
        // }        
        this.spinner.hide();

      }
      // this.getWorkLogList();

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  //********************* To Dos End ********************

  //********************* Work Log Begin ********************

  getWorkLogList() {
    this.workLogsList = [] as Array<WorkLogDataModel>;
    this.toDosService.getWorkLogsByEmpIdAndToDoId(this.empId, this.updateToDoId).subscribe((workRes: any) => {
      if (workRes.ResponseCode == 100) {// success.

        this.workLogsList = workRes.Data;
      }

      else if (workRes.ResponseCode == 999) {

        this.toastr.error(workRes.Message, 'Error');
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }



  //********************* Work Log End ********************

  //*********************** Get APIs End *****************************

  //****************************** To-Do Related Actions Begin *************************


  //Support Request Form Control
  get srf() {
    return this.supportReqForm.controls;
  }

  /*On Change of solz status i.e if solz status is  "Not Started" or "Analyzing" or "On Hold" or "Duplicate" or "QA Failed" or "Canceled" than ETA and Estimated Effort is not Required, else Bussiness Required.*/
  solzStatusSelected(val: number) {

    // If Employee Logged In.
    if (this.empData.contact == null) {
      if (val == 674180000 || val == 674180009 || val == 674180008 || val == 674180012 || val == 674180010) {
        this.fieldRequiredFlag = false;
        this.startDateFlag = false;
        this.endDateFlag = false;
      }

      else {
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
        this.endDateFlag = true;
      }

      //If Solz status is "Review in progress" or "Review Complete" or "Review Failed" or "Deployed" then change review effort to required or else not required
      if (val == 674180004 || val == 674180005 || val == 674180006 || val == 674180007) {
        this.reviewerEffortfieldFlag = true;
        this.startDateFlag = true;
        this.endDateFlag = true;
      }

      else {
        this.reviewerEffortfieldFlag = false;
      }
      // If "Ready for Review" then flush testing environment
      if (val == 674180003) {
        this.supportReqForm.patchValue({
          testingEnvironment: null
        });
      }
    }
  }
  selectAttachments(event) {
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > 5242880) { // 5 MB in bytes
          this.toastr.error("File size greater 5mb is not acceptable");
          continue; // Skip this file and move on to the next one
        }
        this.returnFileSizeAccount(file.size);
        const reader = new FileReader();
        reader.onload = (res: any) => {
          const uploadFile = {
            filename: "",
            filetype: "",
            bytes: "",
            ID: "",
            Name: "",
          };
          this.datasplit = res.target.result;
          const base64 = this.datasplit.split(",");
          uploadFile["ID"] = "";
          uploadFile["Name"] = "solz_supportrequest";
          uploadFile["bytes"] = base64[1];
          uploadFile["filename"] = file.name;
          uploadFile["filetype"] = file.type;
          this.upLoadFileList.push(uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  }



  returnFileSizeAccount(number) {
    var num = (number / 1048576);//mb
    if (num > 5) {
      this.toastr.error("File size greater 5mb is not acceptable");
    }
  }

  deleteFile(index: number) {
    this.upLoadFileList.splice(index, 1);

    // Clear the file input element
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = '';
  }

  scrollToFirstInvalidControl(form: FormGroup) {
    // Find the first invalid control
    const invalidControl = Object.keys(form.controls).find(key => {
        return form.get(key)?.invalid;
    });

    if (invalidControl) {
        const invalidElement = document.querySelector(`[formControlName="${invalidControl}"]`);
        
        if (invalidElement) {
            invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            (invalidElement as HTMLElement).focus();
        }
    }
}

  onSubmit() {
    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      this.scrollToFirstInvalidControl(this.supportReqForm)
    
    }
    this.spinner.show();
    this.supportReqFormSubmitted = true;
    if (this.fieldRequiredFlag == true || this.reviewerEffortfieldFlag == true) {

      if (this.supportReqForm.value.startDate == null || this.supportReqForm.value.startDate == "0001-01-01T00:00:00" || this.supportReqForm.value.startDate == "Invalid date") {

        this.supportReqForm.controls['startDate'].setErrors({ 'required': true });
        // this.supportReqForm.controls.required['startDate'].touched({'invalid': true})
        // this.supportReqForm.controls.invalid['startDate'].invalid({'invalid': true})
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
        this.endDateFlag = true;
      }
      if (this.supportReqForm.value.endDate == null || this.supportReqForm.value.endDate == "0001-01-01T00:00:00" || this.supportReqForm.value.endDate == "Invalid date") {

        this.supportReqForm.controls['endDate'].setErrors({ 'required': true });
        // this.supportReqForm.controls.required['startDate'].touched({'invalid': true})
        // this.supportReqForm.controls.invalid['startDate'].invalid({'invalid': true})
        this.fieldRequiredFlag = true;
        this.startDateFlag = true;
        this.endDateFlag = true;
      }

      if ((this.supportReqForm.value.implementationeffort == null || this.supportReqForm.value.implementationeffort == 0) && this.fieldRequiredFlag == true) {
        this.supportReqForm.controls['implementationeffort'].setErrors({ 'invalid': true });
      }

      if ((this.supportReqForm.value.revieweffort == null || this.supportReqForm.value.revieweffort == 0) && this.reviewerEffortfieldFlag == true) {
        this.supportReqForm.controls['revieweffort'].setErrors({ 'invalid': true });
      }
    }

    if (this.supportReqForm.invalid) {
      this.spinner.hide();
      return;
    }

    var st = this.supportReqForm.value.solzStatus;
    this.supportReqForm.value.solzStatus = new Object();
    this.supportReqForm.value.solzStatus.Value = st;

    var isr = this.supportReqForm.value.isClientReported;
    this.supportReqForm.value.isClientReported = JSON.parse(isr);

    //Is Bug  New Field isbug Yes Or No
    var ib = this.supportReqForm.value.isBug;
    this.supportReqForm.value.isBug = JSON.parse(ib);

    if (this.supportReqForm.value.testingEnvironment != null) {
      var te = this.supportReqForm.value.testingEnvironment;
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = te;
    }

    else {
      this.supportReqForm.value.testingEnvironment = new Object();
      this.supportReqForm.value.testingEnvironment.Value = 674180004
    }

    if (this.supportReqForm.value.startDate != null) {
      var date = new Date(this.supportReqForm.value.startDate);
      var sd = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.startDate = sd;
    }
    if (this.supportReqForm.value.endDate != null) {
      var date = new Date(this.supportReqForm.value.endDate);
      var ed = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.supportReqForm.value.endDate = ed;
    }

    if (this.supportReqForm.value.expectedResDate != null) {
      var erd = this.supportReqForm.value.expectedResDate;
      this.supportReqForm.value.expectedResDate = moment(erd).format('YYYY-MM-DD');
    }

    if (!this.contactLoggedIn) {
      this.supportReqForm.value.createdBy = this.empId;
      this.supportReqForm.value.CreatedBy = this.empId;
    }

    if (this.assign?.length == 1) {
      if (this.assign[0].Value == 0) {
        this.supportReqForm.value.assigneeId = this.empId;
      }
      else if (this.assign[0].Value == 1) {
        this.supportReqForm.value.assignedReviewerId = this.empId;
      }
    }

    else if (this.assign?.length == 2) {
      this.supportReqForm.value.assigneeId = this.empId
      this.supportReqForm.value.assignedReviewerId = this.empId;
    }

    // deciding which object to send according to who logged in i.e., 

    var updateSupportReqObj;
    if (this.contactLoggedIn) {

      updateSupportReqObj = {
        ID: this.supportReqForm.value.ID,
        comment: this.supportReqForm.value.comment,
        ItemId: this.supportReqForm.value.ItemId,
        itemNo: this.supportReqForm.value.itemNo,
        projectId: this.supportReqForm.value.projectId,
        projectName: this.supportReqForm.value.projectName,
        solzStatus: this.supportReqForm.value.solzStatus
      }
    }
    else {
      updateSupportReqObj = this.supportReqForm.value;
    }

    //else if (this.supportReqForm.value.newEmpId == 0 )
    this.toDosService.updateToDo(updateSupportReqObj).subscribe((updateRes: any) => {

     
      if (updateRes.ResponseCode == 100) { // success
        if (this.upLoadFileList.length != 0) { // upload files if any
          for (let i = 0; i < this.upLoadFileList.length; i++) {
            this.upLoadFileList[i].ID = this.updateToDoId;
          }

          var attachFiles = { itemDetails: this.upLoadFileList }
          this.uploadFiles(attachFiles);
        }
       

        else { // else just show message.
          this.spinner.hide();
          this.toastr.success(updateRes.Message, 'Success', {
            positionClass: 'toast-top-full-width',
            timeOut: 5000
          });
          this.spinner.hide();
          location.reload();
          // this.router.navigate(['/ESSPortal/to_dos/my_to_dos']);

        }
      }

      else if (updateRes.ResponseCode == 999) {
        this.toastr.error(updateRes.Message, 'Error');
        this.spinner.hide();

      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });


  }

  // async uploadFiles(attachFiles: any) {
  //   await this.supportReqService.postUploadFilesWithToDo(attachFiles).subscribe((upRes: any) => {
  //     if (upRes.ResponseCode == 100) { // success
  //       this.toastr.success(upRes.Message, 'Success', {
  //         positionClass: 'toast-top-full-width',
  //         timeOut: 5000
  //       });
  //       this.router.navigate(['/ESSPortal/to_dos/my_to_dos']);
  //     }

  //     else if (upRes.ResponseCode == 999) {
  //       this.spinner.hide();
  //       this.toastr.error(upRes.Message);
  //     }

  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     this.toastr.error(error.message);
  //   });
  // }
  //CRM SharePoint API
  async uploadFiles(attachFiles: any) {
    await this.supportReqService.postUploadFilesWithToDos(attachFiles).subscribe((upRes: any) => {
      if (upRes.ResponseCode == 100) { // success
        this.toastr.success('', 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
        this.spinner.hide();
        location.reload();
        // this.router.navigate(['/ESSPortal/to_dos/my_to_dos']);
      }

      else if (upRes.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(upRes.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }
  expandLabel(selected: any) {
    if (selected == "true") {
      this.linkedTaskFlag = true;
      this.showBugButtonFlag = false;
    }
    else {
      this.linkedTaskFlag = true;
      this.showBugButtonFlag = true;
    }
  }

  createNewTodo() {
    this.router.navigate(['/ESSPortal/to_dos/new_to_do']);
  }
  download(file: UploadFileDataModel) {
    import("file-saver").then(FileSaver => {
      var contentbase64 = file.bytes;
      var contenttype = file.filetype;
      var fileFormat = "data:" + contenttype + ";base64," + contentbase64;
      //download file
      FileSaver.saveAs(fileFormat, file.filename);
    });


  }

  GetTodosByLinkedTaskIdOnBug(linkedTaskAddId) {
    this.supportReqService.GetTodosByLinkedTaskIdOnBug(linkedTaskAddId).subscribe((bugResp: any) => {
      if (bugResp.ResponseCode == 100) { // success
        this.bugListData = bugResp.Data;
      }

      else if (bugResp.ResponseCode == 999) {
        this.spinner.hide();
        this.toastr.error(bugResp.Message);
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });

  }
  //send Id's of These Fields on router
  addBug() {
    this.router.navigate(['/ESSPortal/to_dos/to-dos-bugs/', this.updateToDoId, '/' + this.supportReqForm.value.projectId,
      '/' + this.supportReqObj.parentId, '/' + this.supportReqObj.title, '/' + this.supportReqObj.isBug, '/' + this.edit, '/'
      + this.supportReqObj.assigneeId, '/' + this.supportReqObj.assignedReviewerId,
      '/' + this.supportReqObj.itemNo, '/' + this.supportReqObj.testingEnvironment.Value]);

  }

  //Task Bug's grid list record update 
  updateLinkedBug(recordId) {
    this.updateToDoId = recordId
    this.router.navigate(['/ESSPortal/to_dos/update_to_do/' + this.updateToDoId]);
    this.getToDosDataToUpdate()
  }
  //********************** Worklog Related Actions Begin *************************


  //*********************************** Add Bug Related Actions End *************************






  //*********************************** To-Do Related Actions End *************************


  //********************** Worklog Related Actions Begin *************************

  // here sending to-do id to my worklog page to create a new to-do respective worklog.
  createWorkLog() {

    this.router.navigate(['/ESSPortal/to_dos/my_worklog/AddWork/' + this.updateToDoId]);
  }

  // here sending worklog id to my worklog page to edit already existing worklog.
  async EditWorklog(workLogRow: any) {

    this.router.navigate(['/ESSPortal/to_dos/my_worklog/EditWork/' + workLogRow.ID]);
  }



  async deleteWorkLog(workLogRow: any) {
    await this.supportReqService.postdeleteTimeSheet(workLogRow).subscribe((delRes: any) => {
      if (delRes.ResponseCode == 100) { // success
        this.toastr.success(delRes.Message, 'Success', {
          positionClass: 'toast-top-full-width',
          timeOut: 5000
        });
      }

      else if (delRes.ResponseCode == 999) {
        this.toastr.error(delRes.Message, 'Error');
      }
      this.spinner.hide();
      location.reload();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.message);
    });
  }

  // //********************** Worklog Related Actions End *************************
  // back() {
  //   this.toDosService.updateStateInspection(this.searchFilterVal);
  // }
}
