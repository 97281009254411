import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { PerformanceReviewService } from '../../../_services/performance-review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-add-employee-goals',
  templateUrl: './add-employee-goals.component.html',
  styleUrls: ['./add-employee-goals.component.scss']
})
export class AddEmployeeGoalsComponent implements OnInit {
  empGoalsForm: FormGroup;
  fieldRequiredFlag: boolean = false;
  empId: string;
  statusresionval: any;
  status: any;
  Filterstatus: [];
  todayDate = new Date();
  maxDate = moment(this.todayDate).format('YYYY-MM-DD');
  goalFormSubmitted: boolean = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private PerformanceReviewService: PerformanceReviewService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    this.empId = localStorage.getItem("empId")
  }

  ngOnInit(): void {


    this.empGoalsForm = this.fb.group({
      EmployeeName: [''],
      PerformanceYear: [''],
      Goals: ['', Validators.required],
      achievedDate: ['', Validators.required],
      ExpOutcome: ['', Validators.required],
      percentageAchieved: ['', Validators.required],
      statusReason: ['', Validators.required],
      employeeComments: ['', Validators.required],
      managerComments: ['',]

    });
    this.empGoalsForm.patchValue({
      statusReason: '674180002'
    })
    this.dropdownOfStatus()
  }

  //Employee Form Control
  get egl() {
    return this.empGoalsForm.controls;
  }

  checkValue(event) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  }
  dropdownOfStatus() {

    this.PerformanceReviewService.getDropDownDataOfStatus().subscribe((responce: any) => {
      if (responce.ResponseCode == 100) {

        this.status = responce.Data
        this.Filterstatus = this.status.filter(element => {
          return element.Label === 'In Progress' || element.Label === 'New' ||
            element.Value === 1 || element.Value === 674180002;
        });
      }
    })
  }

  statusReason(status: any) {
    this.statusresionval = status
  }
  submit() {
    this.goalFormSubmitted = true
    this.spinner.show();
    // this.router.navigate(['/ESSPortal/employee-goals-list']);

    if (this.empGoalsForm.invalid) {
      // Mark all form controls as touched to trigger validation messages
      Object.values(this.empGoalsForm.controls).forEach(control => {
        control.markAsTouched();
        this.fieldRequiredFlag = true;
      });
      this.spinner.hide();
      return;

    }
    let obj = {
      employeeId: this.empId,
      expOutcome: this.empGoalsForm.value.ExpOutcome,
      achievebyDate: this.empGoalsForm.value.achievedDate,
      comment: this.empGoalsForm.value.employeeComments,
      managerComment: this.empGoalsForm.value.managerComments,
      goal: this.empGoalsForm.value.Goals,
      // PerformanceYear: this.empGoalsForm.value.PerformanceYear,    
      percentAchieved: this.empGoalsForm.value.percentageAchieved,
      statusReason: {

        value: this.empGoalsForm.value.statusReason
      }

    }

    this.PerformanceReviewService.postaddEmployeeGoals(obj).subscribe((responce: any) => {
      if (responce.isSuccessful == true) {

        this.toastr.success(responce?.messageDetail?.message, 'Success', {//Success
          positionClass: 'toast-top-full-width',
        });

        setTimeout(function () {
          location.reload();
        }, 1000);
        this.spinner.hide();
        this.router.navigate(['/ESSPortal/employee-goals-list']);
      }

      else if (responce.isSuccessful == false) {
        this.toastr.error(responce?.messageDetail?.message, 'Error');
        this.spinner.hide();
      }
    },

      error => {
        this.spinner.hide();
        this.toastr.error(error?.error?.messageDetail?.message)
      })
  }
}


